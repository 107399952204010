@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');

:root {
  --primary-color: #6C63FF;
  --primary-hover: #5A52D5;
  --text-color: #333;
  --background-color: #F0F2F5;
  --box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.2);
}

body {
  font-family: 'Inter', sans-serif;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  margin: 0;
  padding: 0;
  color: var(--text-color);
  line-height: 1.6;
  overflow-x: hidden;
  min-height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  position: relative;
  z-index: 1;
}

.header {
  text-align: center;
  margin-bottom: 60px;
}

.title {
  font-size: 4.5rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  position: relative;
  display: inline-block;
}

.title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background-color: var(--primary-color);
  border-radius: 2px;
}

.subtitle {
  font-size: 1.3rem;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
  font-weight: 300;
  max-width: 600px;
  margin: 20px auto 0;
}

.content {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.box {
  width: 400px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 30px;
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
}

.box:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px -5px rgba(0, 0, 0, 0.3);
}

.inner-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upload-options {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.upload-option {
  text-align: center;
  cursor: pointer;
  padding: 20px;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.upload-option:hover {
  background-color: rgba(108, 99, 255, 0.1);
  transform: scale(1.05);
}

.icon {
  font-size: 4rem;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.uploaded-image-container {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}

.uploaded-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 0, 0, 0.7);
  color: #fff;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.delete-btn:hover {
  background-color: rgba(255, 0, 0, 1);
  transform: scale(1.1);
}

.palette-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 300px;
}

.nav-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--primary-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-btn:hover {
  color: var(--primary-hover);
  transform: scale(1.1);
}

.palette-colors {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  border-radius: 10px;
  overflow: hidden;
}

.color-bar {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s ease;
}

.color-bar:hover {
  transform: scale(1.02);
}

.color-code {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 0.05em;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.download-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.download-btn {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.download-btn:hover {
  background-color: var(--primary-hover);
  transform: translateY(-2px);
}

.message {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
}

.error {
  color: #e53e3e;
}

.color-count-selector {
  text-align: center;
  margin-top: 20px;
  color: #fff;
}

.color-count-selector label {
  margin-right: 10px;
}

.color-count-selector input {
  width: 200px;
}

.background-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.color-circle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.1;
}

.about-section {
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  margin-top: 60px;
  box-shadow: var(--box-shadow);
}

.about-section h2 {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 2rem;
  margin-bottom: 20px;
}

.about-section p {
  margin-bottom: 15px;
  font-size: 1.1rem;
  line-height: 1.8;
}

.footer {
  text-align: center;
  margin-top: 60px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}

.background-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.color-circle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.1;
}

.logo-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 50px; /* Adjust this value based on your logo size */
  height: auto;
  margin-right: 15px; /* Space between logo and title */
}

.title {
  /* Existing styles remain */
  display: inline; /* Change from inline-block to inline */
}

.branding {
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin-top: 30px;
  font-weight: 500;
}

.branding a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.branding a:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}

@media (max-width: 900px) {
  .content {
    flex-direction: column;
    align-items: center;
  }
  
  .box {
    width: 90%;
    max-width: 400px;
    margin-bottom: 20px;
  }

  .upload-options, .uploaded-image-container, .palette-navigation {
    height: 250px;
  }

  .icon {
    font-size: 3rem;
  }

  .download-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .download-btn {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .box {
    padding: 20px;
  }

  .upload-options, .uploaded-image-container, .palette-navigation {
    height: 200px;
  }

  .icon {
    font-size: 2.5rem;
  }

  .about-section, .footer {
    padding: 20px;
  }
}
